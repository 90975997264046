import React from "react";
import Social from "./Social";

const Home = () => {
    return (<>
        <div className="tokyo_tm_home">
            <div className="home_content">
                <div className="avatar">
                    <div
                        className="image avatar_img"
                        style={{
                            backgroundImage: "url(assets/img/slider/1.png)",
                        }}
                    ></div>
                </div>
                <div className="details">
                    <h3 className="name">Welcome</h3>
                    <p className="job">
                        Hi, I'm Pieter Leek. <br /> <br />

                        I’m a multi-talented professional who moves effortlessly between strategy and execution — from data analysis and IT consultancy to teaching and hands-on renovation work. Whether
                        I’m improving a digital system, guiding a team, or rewiring a building, I bring structure, clarity, and a touch of creativity.
                        With roots in tech, education, and construction, I love turning complexity into clear solutions — always with a practical mindset and an eye for what really works.
                        <br /> <br />
                        Open to freelance projects that need brains, hands, and heart.
                    </p>
                    <Social />
                </div>
            </div>
        </div>
    </>);
};

export default Home;
