import React from "react";

const Intro = () => {
    const introContent = {
        image: "assets/img/slider/PieterLeek.png",
        name: "Pieter Leek",
        designation: "Versatile Consultant | Bridging Digital Solutions and Real-World Execution",
        text: (
            <>
                <p>
                With over 25 years of experience in IT and more than a decade in education and leadership, I bring a unique combination of strategic insight, technical expertise, and people skills. I’ve worked as a software developer, educator, team lead, and consultant — always with a focus on clarity, improvement, and meaningful impact.
                </p>
                <p>
I’m passionate about making complex systems understandable and workable — whether in software, education, teams, or physical spaces. My background spans programming (Java, Python, JavaScript, TypeScript), data analysis, and systems optimization, as well as hands-on renovation work, including electrical installations and building design. I enjoy switching between digital thinking and practical doing — from analyzing data to wiring a building.
Over the years, I've guided countless students and professionals, helping them grow — not just in skills, but in confidence and clarity. I love working with people from diverse backgrounds and am driven by a desire to create spaces — both digital and physical — where people thrive.
</p>
<p>
I’m known as a multi-talented problem-solver with a wide range of interests. Outside of work, I enjoy travel (over 60 countries so far), design, DIY renovation projects, and camping in my Land Rover Defender. I also have a deep appreciation for aesthetics, whether it’s in architecture, user experience, or the design of a well-functioning system.
Currently, I’m available for freelance or consultancy projects in IT, education, data, or renovation — especially those where strategic thinking, hands-on execution, and real change come together.
Let’s connect if you’re looking for someone who can think broadly, act decisively, and bring structure to complexity.
                </p>
            </>
        ),
    };

    return (
        <>
            <div className="top_author_image">
                <img src={introContent.image} alt="about" />
            </div>
            <div className="about_title">
                <h3>{introContent.name}</h3>
                <span>{introContent.designation}</span>
            </div>
            <div className="about_text">{introContent.text}</div>
        </>
    );
};

export default Intro;
